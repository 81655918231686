<template lang="pug">
div
  h4.text-left {{ $t('reportStatementSQC')  }}
  v-tabs(grow).w-100
    v-tabs-slider(color="primary")
    v-tab(v-for="tab in tabs.filter(el => el.checkAccess())" :key="tab.id" :to="tab.link").flex-grow-1.p-2 {{$t(tab.label)}}
  v-card(v-if="checkAccess('menuItem-statementSQC')")
    router-view
</template>

<script>
import TabsList from '@/components/atoms/TabsList.vue'
import { checkAccess } from '@/mixins/permissions'

export default {
  name: 'StatementSQC',
  components: {
    TabsList
  },
  data () {
    return {
      checkAccess,
      tabs: [
        { id: 1, label: 'approvedSQC', link: { name: 'approved', query: { page: '1', page_size: '10', status_document: '24' } }, checkAccess: () => checkAccess('tab-statementSQCApproved') },
        { id: 2, label: 'pendingSQC', link: { name: 'processing', query: { page: '1', page_size: '10', status_document: '25' } }, checkAccess: () => checkAccess('tab-statementSQCProcess') },
        { id: 3, label: 'createdFormPA', link: { name: 'fromPA', query: { page: '1', page_size: '10', status_document: '42' } }, checkAccess: () => checkAccess('tab-statementSQCFromPA') }
      ]
    }
  }
  // beforeCreate () {
  //   this.$store.commit('setActivePage', { name: 'statementSQC', access: checkAccess('menuItem-statementSQC') })
  // }
}
</script>

<style lang="sass">
  @import '../../../assets/sass/seafarer/main'
</style>
