import { render, staticRenderFns } from "./StatementSQC.vue?vue&type=template&id=7c864cca&lang=pug"
import script from "./StatementSQC.vue?vue&type=script&lang=js"
export * from "./StatementSQC.vue?vue&type=script&lang=js"
import style0 from "./StatementSQC.vue?vue&type=style&index=0&id=7c864cca&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports